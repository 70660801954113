import { useEffect } from 'react'
import { Fade } from 'react-awesome-reveal'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { useMain } from '@/contexts/mainContext'

import transparenciaArr from '../../utils/transparencia.json'

export function Transparencia() {
  const { transparencia, setTransparencia } = useMain()

  useEffect(() => {
    const response = transparenciaArr

    setTransparencia(response)
  }, [transparenciaArr])

  return (
    <>
      <Helmet title="Transparência" />
      <section className="flex items-center justify-center pb-8 pt-40 max-[480px]:pt-28">
        <div className="container">
          <div className="flex items-center justify-center gap-8 rounded-2xl border-[1px] border-solid border-cyan-650 py-8 max-[1024px]:flex-col max-[1024px]:border-0">
            <div className="h-[22rem] w-80 rounded-2xl border-[1px] border-white bg-cyan-650 p-4 max-[1024px]:h-auto max-[1024px]:w-full max-[1024px]:pb-8">
              <p className="font-regular mx-auto max-w-56 text-center font-roboto text-base text-white max-[1024px]:max-w-full">
                Reforçando nosso compromisso com a transparência e ética das
                atividades que desempenhamos, disponibilizamos nosso Estatuto
                Social, que define os princípios e objetivos da Cabelegria
                enquanto organização.
              </p>
              <a
                href="/assets/pdfs/Estatuto_Associacao_Cabelegria_de_Auxilio_a_Pessoa_com_Cancer.pdf"
                target="_blank"
                title="Estatuto Social"
                rel="noreferrer"
                className="relative top-4 flex justify-center"
              >
                <button className="inline-flex h-12 items-center justify-center whitespace-nowrap rounded-full border-[1px] border-solid border-white bg-white px-8 font-roboto text-xl font-medium text-cyan-650 ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">
                  Estatuto Social
                </button>
              </a>
            </div>
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-3">
              {transparencia.map((image) => {
                return (
                  <img
                    key={image.id}
                    src={`https://www.cabelegria.org/assets/transparencia/${image.imagem}`}
                    alt={image.name}
                    className="h-auto w-40 rounded-md object-cover"
                  />
                )
              })}
            </div>
          </div>
        </div>
      </section>
      <section className="flex items-center justify-center">
        <div className="container">
          <div className="pb-20 pt-12 max-[1024px]:pt-0 lg:flex lg:flex-col">
            <Fade delay={200}>
              <h2 className="text-center font-roboto text-5xl font-bold text-purple-950 max-[1024px]:text-center max-[1024px]:text-4xl max-[480px]:text-3xl">
                Confira os balanços e relatórios de atividades
              </h2>
            </Fade>
            <div className="grid grid-cols-2 justify-items-center gap-5 pt-20 max-[670px]:grid-cols-1 max-[480px]:pt-8">
              <div className="w-3/4 max-[1024px]:w-full">
                <Fade delay={250}>
                  <Link
                    to="/assets/pdfs/Relatório_de_atividades_Cabelegria_2019b.pdf"
                    target="_blank"
                  >
                    <Button
                      variant="transparent"
                      size="lg"
                      className="w-full rounded-full bg-purple-950 font-roboto text-xl font-medium text-white max-[480px]:text-base"
                    >
                      2019 - Relatório de Atividades
                    </Button>
                  </Link>
                </Fade>
              </div>
              <div className="w-3/4 max-[1024px]:w-full">
                <Fade delay={300}>
                  <Link
                    to="/assets/pdfs/Balanço_Financeiro_e_Demonstrativo_2019.pdf"
                    target="_blank"
                  >
                    <Button
                      variant="transparent"
                      size="lg"
                      className="w-full rounded-full bg-purple-950 font-roboto text-xl font-medium text-white max-[480px]:text-base"
                    >
                      2019 - Balanço Social
                    </Button>
                  </Link>
                </Fade>
              </div>
              <div className="w-3/4 max-[1024px]:w-full">
                <Fade delay={350}>
                  <Link
                    to="/assets/pdfs/Relatório_de_atividades_Cabelegria_2020.pdf"
                    target="_blank"
                  >
                    <Button
                      variant="transparent"
                      size="lg"
                      className="w-full rounded-full bg-purple-950 font-roboto text-xl font-medium text-white max-[480px]:text-base"
                    >
                      2020 - Relatório de Atividades
                    </Button>
                  </Link>
                </Fade>
              </div>
              <div className="w-3/4 max-[1024px]:w-full">
                <Fade delay={400}>
                  <Link
                    to="/assets/pdfs/Balanço_Financeiro_e_Demonstrativo_2020.pdf"
                    target="_blank"
                  >
                    <Button
                      variant="transparent"
                      size="lg"
                      className="w-full rounded-full bg-purple-950 font-roboto text-xl font-medium text-white max-[480px]:text-base"
                    >
                      2020 - Balanço Social
                    </Button>
                  </Link>
                </Fade>
              </div>
              <div className="w-3/4 max-[1024px]:w-full">
                <Fade delay={450}>
                  <Link
                    to="/assets/pdfs/Relatório_de_atividades_2021.pdf"
                    target="_blank"
                  >
                    <Button
                      variant="transparent"
                      size="lg"
                      className="w-full rounded-full bg-purple-950 font-roboto text-xl font-medium text-white max-[480px]:text-base"
                    >
                      2021 - Relatório de Atividades
                    </Button>
                  </Link>
                </Fade>
              </div>
              <div className="w-3/4 max-[1024px]:w-full">
                <Fade delay={500}>
                  <Link
                    to="/assets/pdfs/Balanço_Financeiro_e_Demonstrativo_2021.pdf"
                    target="_blank"
                  >
                    <Button
                      variant="transparent"
                      size="lg"
                      className="w-full rounded-full bg-purple-950 font-roboto text-xl font-medium text-white max-[480px]:text-base"
                    >
                      2021 - Balanço Social
                    </Button>
                  </Link>
                </Fade>
              </div>
              <div className="w-3/4 max-[1024px]:w-full">
                <Fade delay={450}>
                  <Link
                    to="/assets/pdfs/Relatório_de_atividades_2022.pdf"
                    target="_blank"
                  >
                    <Button
                      variant="transparent"
                      size="lg"
                      className="w-full rounded-full bg-purple-950 font-roboto text-xl font-medium text-white max-[480px]:text-base"
                    >
                      2022 - Relatório de Atividades
                    </Button>
                  </Link>
                </Fade>
              </div>
              <div className="w-3/4 max-[1024px]:w-full">
                <Fade delay={500}>
                  <Link
                    to="/assets/pdfs/Balanço_Financeiro_e_Demonstrativo_2022.pdf"
                    target="_blank"
                  >
                    <Button
                      variant="transparent"
                      size="lg"
                      className="w-full rounded-full bg-purple-950 font-roboto text-xl font-medium text-white max-[480px]:text-base"
                    >
                      2022 - Balanço Social
                    </Button>
                  </Link>
                </Fade>
              </div>
              <div className="w-3/4 max-[1024px]:w-full">
                <Fade delay={450}>
                  <Link
                    to="/assets/pdfs/Relatório_de_atividades_2023.pdf"
                    target="_blank"
                  >
                    <Button
                      variant="transparent"
                      size="lg"
                      className="w-full rounded-full bg-purple-950 font-roboto text-xl font-medium text-white max-[480px]:text-base"
                    >
                      2023 - Relatório de Atividades
                    </Button>
                  </Link>
                </Fade>
              </div>
              <div className="w-3/4 max-[1024px]:w-full">
                <Fade delay={500}>
                  <Link
                    to="/assets/pdfs/Balanço_Financeiro_e_Demonstrativo_2023.pdf"
                    target="_blank"
                  >
                    <Button
                      variant="transparent"
                      size="lg"
                      className="w-full rounded-full bg-purple-950 font-roboto text-xl font-medium text-white max-[480px]:text-base"
                    >
                      2023 - Balanço Social
                    </Button>
                  </Link>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
